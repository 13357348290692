class CodeEditor extends HTMLElement {
    constructor() {
        super();
        // Wir arbeiten hier im Light DOM, damit globale Tailwind-Klassen greifen.
    }
    // disconnectedCallback() {
    // // Timer stoppen, falls er noch läuft
    // if (this.animationController.typewriterTimer) {
    // clearTimeout(this.animationController.typewriterTimer);
    // }

    // // Event-Listener entfernen, wenn nötig
    // this.playButton?.removeEventListener('click', this.startTyping);
    // this.pauseButton?.removeEventListener('click', this.pauseTyping);
    // this.resetButton?.removeEventListener('click', this.resetTyping);
    // }
    connectedCallback() {
        // Template inklusive interner Styles und Tailwind-basiertem HTML
        this.innerHTML = /*html*/ `
        <style>
            /* Komponenten-spezifische Styles */
            .editor-shadow {
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                    0 2px 4px -1px rgba(0, 0, 0, 0.06);
            }
        
            .window-button {
                transition: opacity 0.2s ease-in-out;
                cursor: pointer;
            }
        
            .window-button:hover {
                opacity: 0.8;
            }
        
            #codeDisplay {
                font-family: 'Fira Code', monospace;
                line-height: 1.5;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        
            .highlight-tag {
                color: #0000FF;
            }
        
            .highlight-attribute {
                color: #795E26;
            }
        
            .highlight-string {
                color: #A31515;
            }
        
            .highlight-comment {
                color: #008000;
                font-style: italic;
            }
        
            .highlight-tailwind {
                color: #2563EB;
            }
        
            @keyframes blink {
                0% {
                    opacity: 0;
                }
        
                50% {
                    opacity: 1;
                }
        
                100% {
                    opacity: 0;
                }
            }
        
            .cursor {
                display: inline-block;
                width: 2px;
                height: 1.2em;
                background-color: #000;
                animation: blink 0.7s infinite;
                vertical-align: middle;
            }
        </style>
        
        <div class="w-full max-w-[800px] bg-white dark:bg-gray-800 rounded-lg shadow-2xl overflow-hidden editor-shadow lg:me-8">
            <!-- Titelleiste -->
            <div class="bg-gray-200 dark:bg-gray-900 px-4 py-2 flex items-center space-x-2">
                <div class="flex space-x-2">
                    <div id="resetButton" class="w-3 h-3 rounded-full bg-red-500 window-button"></div>
                    <div id="pauseButton" class="w-3 h-3 rounded-full bg-yellow-500 window-button"></div>
                    <div id="playButton" class="w-3 h-3 rounded-full bg-green-500 window-button"></div>
                </div>
                <div class="grow text-center text-sm text-gray-600 dark:text-gray-400 font-medium">
                    index.html
                </div>
            </div>
            <!-- Code Display Bereich -->
            <div class="bg-white dark:bg-gray-950 dark:text-gray-400 p-4">
                <pre id="codeDisplay" class="text-xs sm:text-sm h-52 sm:h-96 overflow-auto"></pre>
            </div>
            <!-- Statusleiste -->
            <div class="bg-gray-100 dark:bg-gray-800 px-4 py-2 text-xs text-gray-600 flex justify-between items-center border-t border-gray-200 dark:border-gray-800 dark:text-gray-400">
                <span class="flex items-center">
                    <span class="w-2 h-2 bg-orange-500 rounded-full mr-2"></span>
                    <span>HTML</span>
                </span>
                <span id="lineCol">Zeile 1, Spalte 1</span>
            </div>
        </div>
        `;

        // Interne Zustandsverwaltung
        this.animationController = {
            isTyping: false,
            isPaused: false,
            currentIndex: 0,
            typewriterTimer: null,
            currentLine: 1,
            currentColumn: 1
        };

        // Referenzen zu den relevanten Elementen
        this.codeDisplay = this.querySelector('#codeDisplay');
        this.lineCol = this.querySelector('#lineCol');
        this.playButton = this.querySelector('#playButton');
        this.pauseButton = this.querySelector('#pauseButton');
        this.resetButton = this.querySelector('#resetButton');

        // Der animierte Code (der hier als Beispiel dargestellt wird)
        this.code = `
<!DOCTYPE html>
<html lang="de">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Meine Tailwind CSS Webseite</title>
    <script src="https://cdn.tailwindcss.com"></script>
</head>

<body class="bg-gray-100 min-h-screen flex flex-col">
    <header class="bg-blue-600 text-white shadow-md">
        <nav class="container mx-auto px-4 py-4 flex justify-between items-center">
            <h1 class="text-2xl font-bold">Meine Webseite</h1>
            <ul class="flex space-x-4">
                <li><a href="#" class="hover:text-blue-200">Startseite</a></li>
                <li><a href="#" class="hover:text-blue-200">Über uns</a></li>
                <li><a href="#" class="hover:text-blue-200">Kontakt</a></li>
            </ul>
        </nav>
    </header>
    <main class="container mx-auto px-4 py-8 grow">
        <section class="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 class="text-2xl font-semibold mb-4">Willkommen</h2>
            <p class="text-gray-700">Dies ist eine Beispielseite mit Tailwind CSS. Tailwind ermöglicht es uns, schnell ansprechende Designs zu erstellen, ohne benutzerdefiniertes CSS schreiben zu müssen.</p>
        </section>
        <section class="grid md:grid-cols-2 gap-6">
            <div class="bg-white rounded-lg shadow-md p-6">
                <h3 class="text-xl font-semibold mb-2">Funktionen</h3>
                <ul class="list-disc list-inside text-gray-700">
                    <li>Responsive Design</li>
                    <li>Vorgefertigte Komponenten</li>
                    <li>Anpassbare Stile</li>
                </ul>
            </div>
            <div class="bg-white rounded-lg shadow-md p-6">
                <h3 class="text-xl font-semibold mb-2">Vorteile</h3>
                <ul class="list-disc list-inside text-gray-700">
                    <li>Schnelle Entwicklung</li>
                    <li>Konsistentes Design</li>
                    <li>Einfach zu lernen</li>
                </ul>
            </div>
        </section>
    </main>
    <footer class="bg-gray-800 text-white py-4">
        <div class="container mx-auto px-4 text-center">
            <p>&copy; 2023 Meine Tailwind CSS Webseite. Alle Rechte vorbehalten.</p>
        </div>
    </footer>
</body>

</html>`;

        // Event-Listener für die Steuerung (Play/Pause/Reset)
        this.playButton.addEventListener('click', () => this.startTyping());
        this.pauseButton.addEventListener('click', () => this.pauseTyping());
        this.resetButton.addEventListener('click', () => this.resetTyping());

        // Automatischer Start (auch nützlich bei PJAX, da connectedCallback immer aufgerufen wird)
        setTimeout(() => this.startTyping(), 500);
    }

    // Hilfsfunktion zum Escapen von HTML-Zeichen
    escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;").replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    // Syntax-Highlighting (einfaches Beispiel)
    highlightSyntax(code) {
        const highlightedCode = code
            .replace(/(&lt;[\/]?[a-zA-Z0-9-]+[^&]*?&gt;)/g, (match) => {
                const tagHighlighted = match.replace(/(&lt;[\/]?[a-zA-Z0-9-]+)/g, '<span class="highlight-tag">$1</span>');
                return tagHighlighted.replace(/\s+([a-zA-Z-]+)=(&quot;.*?&quot;)/g, (attrMatch, attr, value) => {
                    return ` <span class="highlight-attribute">${attr}</span>=${value}`;
                });
            })
            .replace(/(&quot;.*?&quot;)/g, '<span class="highlight-string">$1</span>')
            .replace(/class=&quot;(.*?)&quot;/g, (match, p1) => {
                return 'class=&quot;' + p1.replace(/([a-zA-Z0-9:_-]+)/g, '<span class="highlight-tailwind">$1</span>') + '&quot;';
            });
        return highlightedCode;
    }

    // Aktualisierung von Zeile und Spalte
    updateLineAndColumn(char) {
        this.animationController.currentColumn++;
        if (char === '\n') {
            this.animationController.currentLine++;
            this.animationController.currentColumn = 1;
        }
        this.lineCol.textContent = `Zeile ${this.animationController.currentLine}, Spalte ${this.animationController.currentColumn}`;
    }

    // Typewriter-Animation
    typeWriter() {
        if (this.animationController.isPaused ||
            this.animationController.currentIndex >= this.code.length) {
            return;
        }
        const char = this.code[this.animationController.currentIndex];
        const partialCode = this.code.substring(0, this.animationController.currentIndex + 1);
        const escapedCode = this.escapeHtml(partialCode);
        const highlightedCode = this.highlightSyntax(escapedCode);
        this.codeDisplay.innerHTML = highlightedCode + '<span class="cursor"></span>';
        this.updateLineAndColumn(char);
        this.codeDisplay.scrollTop = this.codeDisplay.scrollHeight;
        this.animationController.currentIndex++;
        this.animationController.typewriterTimer = setTimeout(
            () => this.typeWriter(),
            Math.random() * 100 + 50 // Zufällige Verzögerung für einen natürlichen Tipp-Effekt
        );
    }

    startTyping() {
        if (this.animationController.isTyping && !this.animationController.isPaused) return;
        if (this.animationController.isPaused) {
            this.animationController.isPaused = false;
            this.typeWriter();
            return;
        }
        this.resetTyping();
        this.animationController.isTyping = true;
        this.typeWriter();
    }

    pauseTyping() {
        this.animationController.isPaused = true;
        clearTimeout(this.animationController.typewriterTimer);
    }

    resetTyping() {
        clearTimeout(this.animationController.typewriterTimer);
        this.animationController = {
            isTyping: false,
            isPaused: false,
            currentIndex: 0,
            typewriterTimer: null,
            currentLine: 1,
            currentColumn: 1
        };
        this.codeDisplay.innerHTML = '';
        this.lineCol.textContent = `Zeile ${this.animationController.currentLine}, Spalte ${this.animationController.currentColumn}`;
    }
}

customElements.define('code-editor', CodeEditor);